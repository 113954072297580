




























































































































































	import { Component, Vue, Watch } from 'vue-property-decorator'
	import { apiShopDetail, apiShopAdd, apiShopEdit, apiSetMealLists, apiMapGet, apiMapRegionSearch } from '@/api/shop'
	import historyMeal from './components/history-meal.vue'
	import { debounce } from '@/utils/util'
	import lsPagination from '@/components/ls-pagination.vue'
	import ExportData from '@/components/export-data/index.vue'
	import LsDialog from '@/components/ls-dialog.vue'
	import AreaSelect from '@/components/area-select.vue'
	import { importCDN } from '@/utils/importCDN'
  import MaterialSelect from "@/components/material-select/index.vue";
	enum PageMode {
	    'ADD' = 'add', // 添加
	    'EDIT' = 'edit' // 编辑
	}
	@Component({
		components: {
      MaterialSelect,
			historyMeal,
			AreaSelect
		}
	})
	
	export default class ListsDetail extends Vue {
		/** S Data **/
		
		identity : number | null = null; // 当前编辑用户的身份ID
		 mode: string = PageMode.ADD
		setMealData : any = []
		tencentMapKey ?: string
		// 腾讯地图对象
		tencentMap : any = undefined
		// 腾讯地图锚点对象
		markerLayer : any = undefined
		// 添加商城表单数据
		form : any = {
			name: '', // 名称
			super_admin: '', // 账号
			super_password: '', // 密码
			super_password_confirm: '', // 确认密码
			status: 1, // 商城状态 0-停止服务 1-开启服务
      is_auth_user_money: 1,
      is_auth_user_award_money: 1,
			contact: '', // 联系人
			contact_mobile: '', // 联系人手机号
			domain_alias: '', // 域名别名
			is_check_domain: 1, // 是否验证域名
			shop_url: '', // 当前域名
			set_meal_id: '', // 套餐ID
			expires_time: '', // 到期时间
			remark: '', // 备注
			shop_data: {},
			address: '',
			province: '',
			city: '',
			district: '',
			latitude:'',
			longitude:''
		};

		// 表单校验
		rules = {
			name: [{ required: true, message: '请输入商城名称', trigger: 'blur' }],
			super_admin: [
				{ required: true, message: '请输入商城名称', trigger: 'blur' }
			],
			super_password: [
				{ required: true, message: '请输入密码', trigger: 'blur' },
				{
					validator: (rule : object, value : string, callback : any) => {
						!value ? callback(new Error('请输入密码')) : callback()
					},
					trigger: 'blur'
				}
			],
			super_password_confirm: [
				{ required: true, message: '请再次输入密码', trigger: 'blur' },
				{
					validator: (rule : object, value : string, callback : any) => {
						if (this.form.password) {
							if (!value) callback(new Error('请再次输入密码'))
							if (value !== this.form.password) { callback(new Error('两次输入密码不一致!')) }
						}
						callback()
					},
					trigger: 'blur'
				}
			],
			set_meal_id: [{ required: true, message: '请选择套餐', trigger: ['blur', 'change'] }],
			expires_time: [{ required: true, message: '请选择到期时间', trigger: ['blur', 'change'] }],
			province: [{ required: true, message: '请选择省', trigger: ['blur', 'change'] }],
			city: [{ required: true, message: '请选择市', trigger: ['blur', 'change'] }],
			district: [{ required: true, message: '请选择区', trigger: ['blur', 'change'] }],
			address: [{ required: true, message: '请输入详细地址', trigger: ['blur', 'change'] }],
			latitude: [{ required: true, message: '请输入纬度', trigger: ['blur', 'change'] }],
		};

		/** E Data **/

		/** S Methods **/
		// 点击表单提交
		onSubmit(formName : string) {
			// 验证表单格式是否正确
			const refs = this.$refs[formName] as HTMLFormElement
			refs.validate((valid : boolean) : any => {
				if (!valid) return

				if (!this.identity) {
					this.handleShopAdd()
				} else {
					this.handleShopEdit()
				}
			})
		}

		// 添加商城
		async handleShopAdd() : Promise<void> {
			await apiShopAdd({ ...this.form })
			setTimeout(() => this.$router.go(-1), 500)
		}

		// 编辑商城
		async handleShopEdit() : Promise<void> {
			await apiShopEdit({ ...this.form, id: this.identity })
			setTimeout(() => this.$router.go(-1), 500)
		}

		// 获取详情
		async getShopDetailFunc() : Promise<void> {
			const res : any = await apiShopDetail({
				id: this.identity as number
			})
			Object.keys(res).map((key) => {
				this.$set(this.form, key, res[key])
			})
		}

		// 获取套餐列表
		async getSetMealLists() : Promise<void> {
			const { lists } = await apiSetMealLists({ status: 1 })
			this.setMealData = lists
		}
		/** E Methods **/
		// 初始化腾讯地图
		initTencentMap() {
			const TencentMap = (window as any).TMap

			if (this.mode === PageMode.ADD) {
				this.form.latitude = '39.954104'
				this.form.longitude = '116.357503'

				this.getCurrentLocation().then((data : any) => {
					this.form.latitude = data.lat
					this.form.longitude = data.lng

					this.tencentMap.setCenter({ lat: data.lat, lng: data.lng })
					this.markerLayer.updateGeometries([
						{
							styleId: 'me',
							id: '1',
							position: new TencentMap.LatLng(data.lat, data.lng)
						}
					])
				})
			}

			const latLng = {
				lat: this.form.latitude,
				lng: this.form.longitude
			}

			//定义地图中心点坐标
			const center = new TencentMap.LatLng(latLng.lat, latLng.lng)

			//定义map变量，调用 TencentMap.Map() 构造函数创建地图
			this.tencentMap = new TencentMap.Map(document.getElementById('tencent-map'), {
				center: center, //设置地图中心点坐标
				zoom: 17.2, //设置地图缩放级别
				pitch: 43.5, //设置俯仰角
				rotation: 45 //设置地图旋转角度
			})

			//创建并初始化MultiMarker
			this.markerLayer = new TencentMap.MultiMarker({
				map: this.tencentMap, //指定地图容器
				styles: {
					me: new TencentMap.MarkerStyle({
						width: 34, // 点标记样式宽度（像素）
						height: 34, // 点标记样式高度（像素）
						src: require('@/assets/images/icon_marker.png'), //图片路径
						anchor: { x: 17, y: 34 }
					})
				},
				geometries: [
					{
						id: '1',
						styleId: 'me',
						position: new TencentMap.LatLng(latLng.lat, latLng.lng)
					}
				]
			})
			// 监听地图点击事件
			this.tencentMap.on('click', (data:any) => {
				const { latLng, poi } = data
				this.markerLayer.updateGeometries([
					{
						styleId: 'me',
						id: '1',
						position: new TencentMap.LatLng(latLng.lat, latLng.lng)
					}
				])
				this.form.latitude = latLng.lat
				this.form.longitude = latLng.lng
			})
		}

		// 导入腾讯地图API
		loadTencentMapScript() {
			const TENTCENT_MAP_API = `https://map.qq.com/api/gljs?v=1.exp&key=${this.tencentMapKey}`
			importCDN(TENTCENT_MAP_API).then(() => this.initTencentMap())
		}

		// 获取当前位置
		getCurrentLocation() {
			return new Promise((resolve : Function, reject : Function) => {
				const URL = 'https://mapapi.qq.com/web/mapComponents/geoLocation/v/geolocation.min.js'

				importCDN(URL)
					.then(() => {
						const QQ = (window as any).qq
						const geolocation = new QQ.maps.Geolocation(this.tencentMapKey, 'admin')
						geolocation.watchPosition((data : any) => {
							geolocation.clearWatch()
							resolve(data)
						})
					})
					.catch(err => reject(err))
			})
		}

		// 地图搜索
		onMapSearch() {
			if (!this.tencentMap) {
				return this.$message.error('地图未加载完成')
			}

			apiMapRegionSearch({
				keyword: encodeURI(this.form.address),
				boundary: `region(${this.form.district ?? this.form.city}, 0)`,
				key: this.tencentMapKey!
			})
				.then(({ data }) => {
					if (!data.length) {
						return this.$message.info(`没有找到“${this.form.address}”的相关地点`)
					}

					const TencentMap = (window as any).TMap
					const geometries : any = []

					// 清除所有标点
					this.markerLayer.setGeometries([])
					// 添加自己位置的标点
					this.markerLayer.add([
						{
							id: '1',
							styleId: 'me',
							position: new TencentMap.LatLng(this.form.latitude, this.form.longitude)
						}
					])

					data.forEach((item : any) => {
						const { lat, lng } = item.location
						geometries.push({
							id: item.id,
							styleId: 'marker',
							position: new TencentMap.LatLng(lat, lng),
							properties: {
								title: item.title
							}
						})
					})

					// 更改地图中心点
					const { lat, lng } = data[0].location
					this.tencentMap.setCenter({ lat, lng })
					// 添加标点
					this.markerLayer.add(geometries)
				})
				.catch(err => {
					// this.$message.error('地图搜索出现了点问题，请重新尝试。')
				})
		}
		async getMapKey() {
			const data = await apiMapGet()
			return data.tencent_map_key
		}
		/** S Life Cycle **/
		async created() {
			const query : any = this.$route.query

			// 编辑模式：初始化数据
			this.identity = +query.id
			if (+query.id) {
				this.getShopDetailFunc()
			}

			this.onSubmit = debounce(this.onSubmit, 2000)
			this.getSetMealLists()
			
			this.tencentMapKey = await this.getMapKey()
			this.loadTencentMapScript(); 
			(window as any).handleSearchMap = (data : any) => {}
			/** E Life Cycle **/
		}
	}
